import React from 'react';
import './Contact.scss';
import { contacts } from '../../../Data';
import { motion } from 'framer-motion';
import { DiscussionEmbed, CommentCount } from 'disqus-react';
import { FaGithub, FaLinkedin, FaInstagram } from 'react-icons/fa';

const Contact = () => {
  const disqusConfig = {
    url: 'http://www.datasenseit.tech',
    identifier: 'contact-page',
    title: 'Contact Me',
  };

  return (
    <div className="contact-container" id="contact">
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ y: [-20, 0], opacity: 1 }}
        transition={{ duration: 0.6 }}
        className="title"
      >
        <span>Get in Touch</span>
        <h1>Contact Me</h1>
      </motion.div>
      <div className="contact-content">
        <motion.div
          initial={{ x: -50, opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.8 }}
          className="contact-left"
        >
          <h3>Tell Me</h3>
          <p className="contact-text">Your data is safe</p>
          {contacts.map(contact => (
            <div className="contact-item" key={contact.id}>
              <div className="icon">{contact.icon}</div>
              <p>{contact.infoText}</p>
            </div>
          ))}
          <h3>Connect with Me</h3>
          <div className="social-icons">
              <a href="https://instagram.com/_rodrigo.doctor_/" className="social-icon"><FaInstagram /></a>
              <a href="https://github.com/geniusdev-tech/" className="social-icon"><FaGithub /></a>
              <a href="https://linkedin.com/in/geniusdev" className="social-icon"><FaLinkedin /></a>
            </div>
        </motion.div>
        <motion.div
          initial={{ x: 50, opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.8 }}
          className="contact-right"
        >
          <DiscussionEmbed shortname="geniusdev-tech" config={disqusConfig} />
          <CommentCount shortname="geniusdev-tech" config={disqusConfig}>
            Comments
          </CommentCount>
        </motion.div>
      </div>
    </div>
  );
};

export default Contact;
