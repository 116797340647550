import React from 'react';
import Slider from 'react-slick';
import './Skills.scss';
import { icons } from '../../../Data';
import { motion } from 'framer-motion';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Skills = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="skills-container" id="skills">
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ y: [-20, 0], opacity: 1 }}
        transition={{ duration: 0.6 }}
        className="title"
      >
        <span>My Expertise</span>
        <h1>Skills</h1>
      </motion.div>
      
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ y: [-20, 0], opacity: 1 }}
        transition={{ duration: 0.6 }}
        className="content"
      >
        <Slider {...settings}>
          {icons.map((icon, index) => (
            <div key={index} className="card">
              <div className="icon">{icon}</div>
            </div>
          ))}
        </Slider>
      </motion.div>
    </div>
  );
};

export default Skills;
