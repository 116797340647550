import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import axios from 'axios';
//import portfolio from '../../../assets/portfolio.jpeg';
import './Home.scss';
import { motion } from 'framer-motion';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Home = () => {
  const [news, setNews] = useState([]);

  const fetchNews = async () => {
    try {
      const response = await axios.get(
        'https://newsdata.io/api/1/news?apikey=pub_6041194d48ea30602b62a9a19245b5cc7c00a&q=tecnologia&country=br&language=en,pt&category=science,technology'
      );
      setNews(response.data.results);
    } catch (error) {
      console.error('Erro ao buscar notícias:', error);
    }
  };

  useEffect(() => {
    fetchNews();
  }, []);

  const truncateDescription = (text, maxLength) => {
    if (text && text.length > maxLength) {
      return text.substring(0, maxLength) + '...';
    }
    return text;
  };

  const moveVariants = {
    animation: {
      y: [0, -15],
      transition: {
        yoyo: Infinity,
        duration: 2,
        delay: 1,
      },
    },
  };

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <motion.div
      className="container"
      id="home"
      initial={{ y: -15, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ duration: 2, delay: 0.5 }}
    >
      <div className="profile">
        <img src="https://media.giphy.com/media/biSFmaBCo9K6Ut1PDu/giphy.gif" alt="Rodrigo Lima" />
      </div>
      <div className="profile_text">
        <h3 className="name">
          Hi, <span>Welcome !</span>
        </h3>
        <span className="job">Segurança Cibernética | Software Engineering</span>
        <br />
        <span className="text">
          Performance
          <br />
          Creativity and Safety
          <br />
          <center>Make it Happen</center>
        </span>
        <motion.a
          href="#contact"
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.95 }}
          className="connect-button"
          variants={moveVariants}
          animate="animation"
        >
          Connect with me
        </motion.a>
        <div className="specializations">
          <div className="specialization">Systems Operations</div>
          <div className="specialization">Scripts - Python</div>
          <div className="specialization">Full Stack Developer</div>
          <div className="specialization">Quick Support</div>
        </div>
      </div>
      <br /><br />
      <div className="news-section">
        <h2>Últimas Notícias de Tecnologia</h2>
        <Slider {...sliderSettings}>
          {news.map((article, index) => (
            <div key={index} className="news-card">
              <img src={article.image_url} alt={article.title} />
              <h3>{article.title}</h3>
              <p>{truncateDescription(article.description, 100)}</p>
              <a href={article.link} target="_blank" rel="noopener noreferrer">
                Leia mais
              </a>
            </div>
          ))}
        </Slider>
      </div>
    </motion.div>
  );
};

export default Home;
